import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation(); // Hook for translations

  return (
    <div className="privacy-policy-container">
      <h1>{t('privacy_policy')}</h1>
      <p>{t('privacy_intro')}</p>

      <h2>{t('info_we_collect_title')}</h2>
      <p>{t('info_we_collect_description')}</p>

      <h2>{t('how_we_use_info_title')}</h2>
      <p>{t('how_we_use_info_intro')}</p>
      <ul>
        <li>{t('how_we_use_info_1')}</li>
        <li>{t('how_we_use_info_2')}</li>
        <li>{t('how_we_use_info_3')}</li>
        <li>{t('how_we_use_info_4')}</li>
        <li>{t('how_we_use_info_5')}</li>
        <li>{t('how_we_use_info_6')}</li>
        <li>{t('how_we_use_info_7')}</li>
      </ul>

      <h2>{t('sharing_info_title')}</h2>
      <p>{t('sharing_info_intro')}</p>
      <ul>
        <li>{t('sharing_info_1')}</li>
        <li>{t('sharing_info_2')}</li>
        <li>{t('sharing_info_3')}</li>
      </ul>

      <h2>{t('data_retention_title')}</h2>
      <p>{t('data_retention_description')}</p>

      <h2>{t('your_rights_title')}</h2>
      <p>
        {t('your_rights_description')} <a href="mailto:team@rentfy.ca">team@rentfy.ca</a> {t('for_assistance')}.
      </p>

      <h2>{t('security_title')}</h2>
      <p>{t('security_description')}</p>

      <h2>{t('policy_changes_title')}</h2>
      <p>{t('policy_changes_description')}</p>

      <h2>{t('contact_us_title')}</h2>
      <p>
        {t('contact_us_description')} <a href="mailto:team@rentfy.ca">team@rentfy.ca</a>.
      </p>

      <p className="last-updated">{t('last_updated')} September 30th, 2024</p>
    </div>
  );
};

export default PrivacyPolicy;
