import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';

// Define types for property data
interface Property {
  lat: number;
  lng: number;
  price: number;
  beds: number;
  baths: number;
  square_footage: number;
  address: string;
  property_id: string;
  property_type?: string;
  year_built?: number;
  description?: string;
  tax_history?: { [year: string]: number };
  property_history?: { date_sold: string; price_sold: number }[];
}

const SearchProperty: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('');  // To store input value
  const [error, setError] = useState<string | null>(null);  // To store error messages
  const [properties, setProperties] = useState<Property[]>([]);  // To store fetched properties
  const [suggestions, setSuggestions] = useState<string[]>([]);  // To store search suggestions

  // Fetch search suggestions dynamically
  useEffect(() => {
    if (inputValue.trim() !== '') {
      const fetchSuggestions = async () => {
        try {
          const response = await axios.post('http://localhost:5001/searchSuggestions', { query: inputValue });
          setSuggestions(response.data.suggestions);
        } catch (err) {
          console.error('Error fetching search suggestions:', err);
        }
      };

      fetchSuggestions();
    } else {
      setSuggestions([]); // Clear suggestions if input is empty
    }
  }, [inputValue]);

  // Function to handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  // Function to search for properties in Montreal
  // In your handleSearch function
const handleSearch = async () => {
    if (!inputValue.trim()) {
      setError('Please enter an address');
      return;
    }
    setError(null);  // Clear error before new search

    try {
      console.log('Sending request with address:', inputValue);  // Log the inputValue being sent

      const response = await axios.post('http://localhost:5001/searchPropertiesInMontreal', {
        address: inputValue.trim(),  // Ensure trimmed input
      });

      const propertiesData: Property[] = response.data.properties;
      console.log('Response data:', propertiesData);  // Log the response data

      if (propertiesData.length > 0) {
        setProperties(propertiesData);  // Set properties from API
      } else {
        setError('No properties found for this address.');
      }
    } catch (err: any) {  // Cast err to 'any'
        console.error('Error fetching properties:', err.response?.data || err);  // Log detailed error
        setError('Failed to fetch properties.');
    }    
  };

  const fetchPropertiesInMontreal = async () => {
    try {
      const response = await axios.get('https://realtor16.p.rapidapi.com/properties/list', {
        params: {
          city: 'Montreal', 
        },
        headers: {
          'x-rapidapi-key': '8bd5f0a630msh3cfe02c852da299p14bfdbjsncdcf79123245',
          'x-rapidapi-host': 'realtor16.p.rapidapi.com',
        },
      });
  
      console.log('Response data:', response.data);
      return response.data;
    } catch (err: any) {
      console.error('Error fetching properties for Montreal:', err.response?.data || err.message); // Log detailed error
      setError('Failed to fetch properties.');
    }
  };  
  
  // Call the function
  fetchPropertiesInMontreal();

  // Function to format price for display
  const formatPrice = (price: number) => {
    return price >= 1000000
      ? `$${(price / 1000000).toFixed(1)}m`
      : `$${(price / 1000).toFixed(0)}k`;
  };

  return (
    <div className="main-content">
      <h1>Search Properties in Montreal</h1>

      {/* Search bar */}
      <div className="search-bar-container">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter an address"
          className="input-box"
        />
        <button onClick={handleSearch} className="search-button">Search</button>
      </div>

      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} className="suggestion-item" onClick={() => setInputValue(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}

      {error && <p className="error-message">{error}</p>} {/* Error display */}

      {/* Property List */}
      <div className="property-list">
        {properties.map((property, index) => (
          <div key={index} className="property-item">
            <h2>Property ID: {property.property_id}</h2>
            <p>Price: {formatPrice(property.price)}</p>
            <p>Beds: {property.beds}</p>
            <p>Baths: {property.baths}</p>
            <p>Square Footage: {property.square_footage} sqft</p>
            <p>Address: {property.address}</p>
            {property.property_type && <p>Property Type: {property.property_type}</p>}
            {property.year_built && <p>Year Built: {property.year_built}</p>}
            {property.description && <p>Description: {property.description}</p>}
            
            {property.tax_history && (
              <>
                <h3>Tax History:</h3>
                <ul>
                  {Object.entries(property.tax_history).map(([year, tax], i) => (
                    <li key={i}>
                      {year}: ${tax}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {property.property_history && (
              <>
                <h3>Property History:</h3>
                <ul>
                  {property.property_history.map((history, i) => (
                    <li key={i}>
                      Sold on {history.date_sold} for ${history.price_sold}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchProperty;
