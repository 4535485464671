import React, { useState, useEffect } from 'react';
import axios from 'axios'; // For making API calls
import { useNavigate } from 'react-router-dom';
import './PropertyComparison.css'; // Include CSS file for styling

interface Property {
  address: string;
  price: number;
  bedrooms: number;
  bathrooms: number;
  squareFootage: number;
  pricePerSqFt?: number;
  mortgagePayment?: number;
  capRate?: number;
}

const PropertyComparison: React.FC = () => {
  const [properties, setProperties] = useState<Property[]>([]); // Holds up to 3 properties
  const [newProperty, setNewProperty] = useState<Property>({
    address: '',
    price: 0,
    bedrooms: 0,
    bathrooms: 0,
    squareFootage: 0,
    pricePerSqFt: 0,
    mortgagePayment: 0,
    capRate: 0,
  });
  const [suggestions, setSuggestions] = useState<Property[]>([]); // Holds search suggestions

  const navigate = useNavigate(); // For the Back button

  // Function to fetch search suggestions based on user input
  const fetchSearchSuggestions = async (query: string) => {
    try {
      const response = await axios.post('http://localhost:5001/searchSuggestions', { query });
      setSuggestions(response.data.suggestions || []); // Set full property suggestions
    } catch (error) {
      console.error('Error fetching search suggestions:', error);
    }
  };

  // Handle input change for adding a new property
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewProperty({ ...newProperty, [name]: value || '' });

    if (name === 'address' && value.length > 2) {
      fetchSearchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  // Add a new property to the comparison
  const addProperty = () => {
    if (properties.length < 10) {
      const noi = newProperty.price * 0.05; // Example: NOI is 5% of price
      const updatedProperty = {
        ...newProperty,
        pricePerSqFt: newProperty.squareFootage > 0 ? newProperty.price / newProperty.squareFootage : 0,
        mortgagePayment: calculateMortgagePayment(newProperty.price, 3.5, 30),
        capRate: newProperty.price > 0 ? (noi / newProperty.price) * 100 : 0,
      };
      setProperties([...properties, updatedProperty]);
      setNewProperty({
        address: '',
        price: 0,
        bedrooms: 0,
        bathrooms: 0,
        squareFootage: 0,
        pricePerSqFt: 0,
        mortgagePayment: 0,
        capRate: 0,
      });
      setSuggestions([]);
    } else {
      alert('You can only compare up to 10 properties.');
    }
  };

  // Mortgage Payment Calculator
  const calculateMortgagePayment = (price: number, interestRate: number, loanTerm: number): number => {
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;
    return price > 0 ? (price * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -numberOfPayments)) : 0;
  };

  // Remove property from comparison
  const removeProperty = (index: number) => {
    const updatedProperties = properties.filter((_, i) => i !== index);
    setProperties(updatedProperties);
  };

  // Handle selecting a suggestion and filling the form
  const handleSuggestionClick = (suggestion: Property) => {
    setNewProperty({
      address: suggestion.address,
      price: suggestion.price,
      bedrooms: suggestion.bedrooms,
      bathrooms: suggestion.bathrooms,
      squareFootage: suggestion.squareFootage,
      pricePerSqFt: suggestion.pricePerSqFt,
      mortgagePayment: suggestion.mortgagePayment,
      capRate: suggestion.capRate,
    });

    setSuggestions([]); // Clear the suggestions after selection
  };

  // Calculate price difference relative to the first property
  const calculatePriceDifference = (index: number) => {
    if (properties.length > 0 && index > 0) {
      const difference = properties[index].price - properties[0].price;
      return difference >= 0 ? `+${difference.toLocaleString()} CAD` : `${difference.toLocaleString()} CAD`;
    }
    return 'N/A';
  };

  // Calculate square footage difference relative to the first property
  const calculateSquareFootageDifference = (index: number) => {
    if (properties.length > 0 && index > 0) {
      const difference = properties[index].squareFootage - properties[0].squareFootage;
      return difference >= 0 ? `+${difference} sq ft` : `${difference} sq ft`;
    }
    return 'N/A';
  };

  useEffect(() => {
    const tawkScript = document.createElement('script');
    tawkScript.async = true;
    tawkScript.src = 'https://embed.tawk.to/66fc07104cbc4814f7e189b0/1i947n6lg';
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');
    document.body.appendChild(tawkScript);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(tawkScript);
    };
  });

  return (
    <div className="property-comparison-container">
      <div className="top-buttons">
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
        <button className="refresh-button" onClick={() => window.location.reload()}>Refresh</button>
      </div>

      <h1>Compare Up to 10 Properties</h1>

      <div className="add-property-form">
        <h2>Add Property</h2>
        <input
          type="text"
          name="address"
          value={newProperty.address || ''}
          placeholder="Address"
          onChange={handleInputChange}
          autoComplete="off"
        />
        {suggestions.length > 0 && (
          <ul className="suggestions-list">
            {suggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion.address}
              </li>
            ))}
          </ul>
        )}
        <input type="number" name="price" value={newProperty.price || 0} placeholder="Price" onChange={handleInputChange} />
        <input type="number" name="bedrooms" value={newProperty.bedrooms || 0} placeholder="Bedrooms" onChange={handleInputChange} />
        <input type="number" name="bathrooms" value={newProperty.bathrooms || 0} placeholder="Bathrooms" onChange={handleInputChange} />
        <input type="number" name="squareFootage" value={newProperty.squareFootage || 0} placeholder="Square Footage" onChange={handleInputChange} />
        <button className="add-property-button" onClick={addProperty}>Add Property</button>
      </div>

      {properties.length > 0 && (
        <div className="comparison-table">
          <h2>Property Comparison</h2>
          <table>
            <thead>
              <tr>
                <th>Property</th>
                <th>Price</th>
                <th>Bedrooms</th>
                <th>Bathrooms</th>
                <th>Square Footage</th>
                <th>Price per Sq Ft</th>
                <th>Mortgage Payment</th>
                <th>Cap Rate</th>
                <th>Price Difference</th>
                <th>Square Footage Difference</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {properties.map((property, index) => (
                <tr key={index}>
                  <td>{property.address}</td>
                  <td>${property.price.toLocaleString()} CAD</td>
                  <td>{property.bedrooms}</td>
                  <td>{property.bathrooms}</td>
                  <td>{property.squareFootage} sq ft</td>
                  <td>{property.pricePerSqFt?.toFixed(2)} CAD/sq ft</td>
                  <td>${property.mortgagePayment?.toFixed(2)} CAD</td>
                  <td>{property.capRate?.toFixed(2)}%</td>
                  <td style={{ color: property.price - properties[0].price >= 0 ? 'green' : 'red' }}>
                    {calculatePriceDifference(index)}
                  </td>
                  <td style={{ color: property.squareFootage - properties[0].squareFootage >= 0 ? 'green' : 'red' }}>
                    {calculateSquareFootageDifference(index)}
                  </td>
                  <td><button onClick={() => removeProperty(index)}>X</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {properties.length === 0 && <p>No properties added for comparison yet.</p>}
    </div>
  );
};

export default PropertyComparison;
