import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import './FAQ.css'; // Assuming you'll style the FAQ page

const FAQ: React.FC = () => {
  const { t } = useTranslation(); // Hook for translations

  return (
    <div className="faq-container">
      <h1>{t('faq_title')}</h1>
      
      <div className="faq-item">
        <h2>{t('faq_1_question')}</h2>
        <p>{t('faq_1_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_2_question')}</h2>
        <p>{t('faq_2_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_3_question')}</h2>
        <p>{t('faq_3_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_4_question')}</h2>
        <p>{t('faq_4_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_5_question')}</h2>
        <p>{t('faq_5_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_6_question')}</h2>
        <p>{t('faq_6_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_7_question')}</h2>
        <p>{t('faq_7_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_8_question')}</h2>
        <p>{t('faq_8_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_9_question')}</h2>
        <p>{t('faq_9_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_10_question')}</h2>
        <p>{t('faq_10_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_11_question')}</h2>
        <p>{t('faq_11_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_12_question')}</h2>
        <p>{t('faq_12_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_13_question')}</h2>
        <p>{t('faq_13_answer')}</p>
      </div>

      <div className="faq-item">
        <h2>{t('faq_14_question')}</h2>
        <p>{t('faq_14_answer')}</p>
      </div>
    </div>
  );
};

export default FAQ;
