import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth'; 
import { auth } from '../firebaseConfig'; 
import './Login.css';
import { Link } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next'; 
import ReCAPTCHA from 'react-google-recaptcha';

const Login: React.FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!captchaToken) {
      setError('Please complete the CAPTCHA.');
      return;
    }
  
    try {
      setLoading(true);
      
      // You can skip the backend verification of reCAPTCHA for now
      // and directly proceed with Firebase Authentication.
      await signInWithEmailAndPassword(auth, emailRef.current!.value, passwordRef.current!.value);
      navigate('/');
    } catch (err: any) {
      setError(t('login_failed') + ' ' + err.message);
    }
    setLoading(false);
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <button className="back-button" onClick={() => navigate(-1)}>
          &#8592;
        </button>
        <h2>{t('login')}</h2>
      </div>

      {error && <p className="error">{error}</p>}
      <form onSubmit={handleLogin}>
        <label>{t('email')}</label>
        <input
          type="email"
          ref={emailRef}
          required
        />
        <label>{t('password')}</label>
        <input
          type="password"
          ref={passwordRef}
          required
        />

        {/* reCAPTCHA Widget */}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LfVoVoqAAAAAKV9dvVOPy0-FZLSxDe-Co_d4MqF'}
          onChange={setCaptchaToken}
          className="mb-3"
        />

        <button disabled={loading} type="submit">
          {t('login')}
        </button>
      </form>

      <p className="signup-link">
        {t('dont_have_account')} <Link to="/signup">{t('sign_up')}</Link>
      </p>
    </div>
  );
};

export default Login;
