import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth'; 
import { auth } from '../firebaseConfig'; 
import './Signup.css';
import { Link } from 'react-router-dom'; 
import axios from 'axios'; 
import { useTranslation } from 'react-i18next'; 
import ReCAPTCHA from 'react-google-recaptcha';

const Signup: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null); // reCAPTCHA state
  const [loading, setLoading] = useState(false); // Loading state for button
  const navigate = useNavigate();
  const { t } = useTranslation(); 

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError(t('passwords_do_not_match')); 
      return;
    }

    if (!captchaToken) {
      setError(t('please_complete_captcha')); // Ensure CAPTCHA is completed
      return;
    }

    try {
      setLoading(true);
      
      // Step 1: Use Firebase to create a new user
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Step 2: Call backend API to create a Stripe customer and subscribe to free plan
      await axios.post('http://localhost:5001/register', { email });

      // Step 3: Redirect to login after successful signup and subscription
      navigate('/login');
    } catch (err: any) {
      setError(t('signup_failed') + ' ' + err.message); 
    }
    setLoading(false);
  };

  return (
    <div className="signup-container">
      <div className="signup-header">
        <button className="back-button" onClick={() => navigate(-1)}>
          &#8592;
        </button>
        <h2>{t('sign_up')}</h2> 
      </div>

      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSignup}>
        <label>{t('email')}</label> 
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label>{t('password')}</label> 
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label>{t('confirm_password')}</label> 
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />

        {/* reCAPTCHA Widget */}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LfVoVoqAAAAAKV9dvVOPy0-FZLSxDe-Co_d4MqF'}
          onChange={setCaptchaToken}
          className="mb-3"
        />

        <button disabled={loading} type="submit">
          {t('sign_up')}
        </button>
      </form>

      <p className="login-link">
        {t('already_have_account')} <Link to="/login">{t('login')}</Link>
      </p>
    </div>
  );
};

export default Signup;
