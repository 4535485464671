import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import SideNavbar from './SideNavbar';
import Home from './components/Home';
import PropertyDetails from './components/PropertyDetails';
import Footer from './components/Footer';
import PropertyEvaluationPage from './components/PropertyEvaluationPage';
import SearchPropertiesInMontreal from './components/SearchProperty';
import Login from './components/Login';
import Signup from './components/Signup';
import LandingPage from './components/LandingPage';
import SavedProperties from './components/SavedProperties';
import Settings from './components/Settings';
import PricingPlan from './components/PricingPlan';
import MarketInsight from './components/MarketInsight';
import PropertyComparison from './components/PropertyComparison';
import ContactUs from './components/ContactUs';
import SavedValuation from './components/SavedValuation';
import { I18nextProvider } from 'react-i18next';  
import i18n from './i18n'; 
import PropertyComparisonStandard from './components/PropertyComparisonStandard';
import FAQ from './components/FAQ';
import PrivacyPolicy from './components/PrivacyPolicy';
import PropertyDetailsExtended from './components/PropertyDetailsExtended';
import SimilarPropertySales from './components/SimilarPropertySales';

interface Property {
    lat: number;
    lng: number;
    price: number;
    beds: number;
    baths: number;
    square_footage: number;
    address: string;
}

const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

if (isMobile) {
    document.body.innerHTML = '<h1>Sorry, this site is not accessible on mobile devices</h1> <h1>Accurateator.com</h1>';
}

// PublicRoute component to restrict access for logged-in users
const PublicRoute = ({ element }: { element: React.ReactElement }) => {
    const { user } = useAuth();
    return user ? <Navigate to="/" /> : element;
};

// PrivateRoute component to protect routes
const PrivateRoute = ({ element }: { element: React.ReactElement }) => {
    const { user } = useAuth();
    return user ? element : <Navigate to="/get-started" />;
};

// MainLayout component with conditional SideNavbar
const MainLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const location = useLocation();
    const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'light');
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);  // Sidebar state

    const toggleSidebar = () => {
        setIsSidebarOpen((prev) => !prev);  // Function to toggle the sidebar state
    };    

    // Toggle theme between light and dark
    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    // Conditionally render SideNavbar only if NOT on the LandingPage, Login, or Signup
    const hideSideNavbar = ['/get-started', '/login', '/signup'].includes(location.pathname);

    return (
        <div className="app-container">
            {!hideSideNavbar && (
                <SideNavbar 
                    toggleTheme={toggleTheme} 
                    theme={theme} 
                    toggleSidebar={toggleSidebar} 
                    isSidebarOpen={isSidebarOpen}  
                />
            )}
            <div className={`content-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                {children}
            </div>
            <Footer />
        </div>
    );
};

const App: React.FC = () => {
    const [savedProperties, setSavedProperties] = useState<Property[]>([]);

    // Function to add a property to saved properties
    const addPropertyToSaved = (property: Property) => {
        setSavedProperties((prev) => [...prev, property]);
    };

    // Function to remove a property from saved properties
    const removePropertyFromSaved = (property: Property) => {
        setSavedProperties((prev) =>
            prev.filter((p) => p.address !== property.address)
        );
    };

    // Define toggleSidebar and isSidebarOpen here to pass down as props
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen((prev) => !prev);
    };

    return (
        <I18nextProvider i18n={i18n}>  {/* Wrap the app with I18nextProvider */}
            <AuthProvider>
                <Router>
                    <MainLayout>
                        <Routes>
                            <Route path="/get-started" element={<PublicRoute element={<LandingPage />} />} />
                            <Route path="/login" element={<PublicRoute element={<Login />} />} />
                            <Route path="/signup" element={<PublicRoute element={<Signup />} />} />
                            <Route path="/faq"  element={<FAQ />} />
                            <Route path="/pricing-plan" element={<PricingPlan />} />
                            <Route path="/privacy" element={<PrivacyPolicy />} />

                            {/* Private Routes */}
                            <Route path="/evaluate-property" element={<PrivateRoute element={<PropertyEvaluationPage />} />} />
                            <Route path="/property/:lat/:lng" element={<PrivateRoute element={<PropertyDetails />} />} />
                            <Route path="/search-properties" element={<PrivateRoute element={<SearchPropertiesInMontreal />} />} />
                            <Route path="/market-insights" element={<PrivateRoute element={<MarketInsight />} />} />
                            <Route path="/compare-properties" element={<PrivateRoute element={<PropertyComparison />} />} />
                            <Route path="/compare-properties-standard" element={<PrivateRoute element={<PropertyComparisonStandard />} />} />
                            <Route path="/contact-us" element={<PrivateRoute element={<ContactUs />} />} />
                            <Route path="/saved-valuation" element={<PrivateRoute element={<SavedValuation />} />} />
                            <Route path="/property-details-extended" element={<PropertyDetailsExtended />} />
                            <Route path="/similar-property-sales" element={<SimilarPropertySales />} />
                            <Route path="/saved-properties" element={
                                <PrivateRoute element={
                                    <SavedProperties
                                        savedProperties={savedProperties}
                                        removeProperty={removePropertyFromSaved}
                                        toggleSidebar={toggleSidebar}  // Pass toggleSidebar prop
                                        isSidebarOpen={isSidebarOpen}  // Pass isSidebarOpen prop
                                    />
                                } />
                            } />

                            <Route path="/settings" element={<PrivateRoute element={<Settings />} />} />
                            <Route path="/" element={
                                <PrivateRoute element={
                                    <Home
                                        savedProperties={savedProperties}
                                        addPropertyToSaved={addPropertyToSaved}
                                        removePropertyFromSaved={removePropertyFromSaved}
                                        toggleSidebar={toggleSidebar}  // Pass toggleSidebar prop
                                        isSidebarOpen={isSidebarOpen}  // Pass isSidebarOpen prop
                                    />
                                } />
                            } />
                        </Routes>
                    </MainLayout>
                </Router>
            </AuthProvider>
        </I18nextProvider>  
    );
};

export default App;
