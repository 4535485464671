import React from 'react';
import './SideNavbar.css';
import { useNavigate } from 'react-router-dom'; 
import { signOut } from 'firebase/auth'; 
import { auth } from './firebaseConfig'; 
import { FaHome, FaHeart, FaEnvelope, FaCog, FaSignOutAlt, FaArrowLeft, FaArrowRight, FaChartLine, FaBalanceScale } from 'react-icons/fa'; 
import { useTranslation } from 'react-i18next'; 
import axios from 'axios';

interface SideNavbarProps {
  toggleTheme: () => void;
  theme: string;
  toggleSidebar: () => void; 
  isSidebarOpen: boolean;   
}

const SideNavbar: React.FC<SideNavbarProps> = ({ theme, toggleSidebar, isSidebarOpen, toggleTheme }) => {
  const { t, i18n } = useTranslation();
  const [currentPlan, setCurrentPlan] = React.useState('');
  const navigate = useNavigate();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/get-started');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  React.useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const user = auth.currentUser;
        console.log("Current User: ", user); 
        if (user && user.email) {
          const response = await axios.get(`http://localhost:5001/getUserPlan`, {
            params: { email: user.email },
          });
          console.log("Plan fetched: ", response.data.plan);
          setCurrentPlan(response.data.plan || ''); 
          console.log("Current Plan state: ", response.data.plan);
        } else {
          console.error('No user is logged in.');
        }
      } catch (error) {
        console.error('Error fetching user plan:', error);
        setCurrentPlan(''); 
      }
    };    
    fetchUserPlan();
  }, []);

  return (
    <div className={`side-navbar ${isSidebarOpen ? 'expanded' : 'collapsed'}`}>
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        {isSidebarOpen ? <FaArrowLeft /> : <FaArrowRight />}
      </div>
  
      <div className="current-plan">
        {isSidebarOpen && (
          <div>
            <h3>{t('current_plan')}: {currentPlan}</h3>
            {currentPlan === 'Free Plan' && (
              <button className="upgrade-button" onClick={() => navigate('/pricing-plan')}>
                {t('upgrade')}
              </button>
            )}
          </div>
        )}
      </div>
  
      <a href="/" onClick={toggleSidebar}>
        <FaHome className="icon" /> {isSidebarOpen && t('home')}
      </a>
  
      {/* Saved Properties and Market Insights accessible to all plans */}
      <a href="/saved-properties" onClick={toggleSidebar}>
        <FaHeart className="icon" /> {isSidebarOpen && t('saved_properties')}
      </a>
      <a href="/market-insights" onClick={toggleSidebar}>
        <FaChartLine className="icon" /> {isSidebarOpen && t('market_insights')}
      </a>
  
      {/* Show Property Comparison Standard to Standard Plan users */}
      {currentPlan === 'Standard Plan' && (
        <a href="/compare-properties-standard" onClick={toggleSidebar}>
          <FaBalanceScale className="icon" /> {isSidebarOpen && t('compare_properties_standard')}
        </a>
      )}
  
      {/* Property comparison available only to Free and Premium plans */}
      {(currentPlan === 'Free Plan' || currentPlan === 'Premium Plan') && (
        <a href="/compare-properties" onClick={toggleSidebar}>
          <FaBalanceScale className="icon" /> {isSidebarOpen && t('compare_properties')}
        </a>
      )}
  
      <a href="/contact-us" onClick={toggleSidebar}>
        <FaEnvelope className="icon" /> {isSidebarOpen && t('contact')}
      </a>
      <a href="/settings" onClick={toggleSidebar}>
        <FaCog className="icon" /> {isSidebarOpen && t('settings')}
      </a>
  
      <a href="/get-started" onClick={() => { handleLogout(); toggleSidebar(); }}>
        <FaSignOutAlt className="icon" /> {isSidebarOpen && t('log_out')}
      </a>
  
      {isSidebarOpen && (
        <div>
          <div className="theme-switch-container">
            <label className="theme-switch">
              <input
                type="checkbox"
                onChange={toggleTheme} 
                checked={theme === 'dark'}
              />
              <span className="slider"></span>
            </label>
            <span>{theme === 'light' ? t('light_mode') : t('dark_mode')}</span>
          </div>
  
          <div className="language-switch-container">
            <label className="language-switch">
              <input
                type="checkbox"
                onChange={changeLanguage}
                checked={i18n.language === 'fr'}
              />
              <span className="slider"></span>
            </label>
            <span>{i18n.language === 'fr' ? 'Français' : 'English'}</span>
          </div>
        </div>
      )}
    </div>
  );  
};

export default SideNavbar;
