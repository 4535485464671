import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Valuation {
  filename: string;
  url: string;
}

const SavedValuation: React.FC = () => {
  const [valuations, setValuations] = useState<Valuation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchValuations = async () => {
      try {
        const response = await axios.get('/saved-valuations'); // Fetch saved valuations from backend
        setValuations(response.data);
      } catch (err) {
        console.error('Error fetching saved valuations:', err);
        setError('Failed to load saved valuations.');
      } finally {
        setLoading(false);
      }
    };

    fetchValuations(); // Fetch the saved valuations on component mount
  }, []);

  if (loading) {
    return <p>Loading saved valuations...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return (
    <div>
      <h2>Saved Valuations</h2>
      {valuations.length === 0 ? (
        <p>No saved valuations found.</p>
      ) : (
        <ul>
          {valuations.map((valuation) => (
            <li key={valuation.filename}>
              <a href={valuation.url} target="_blank" rel="noopener noreferrer">
                {valuation.filename}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SavedValuation;
