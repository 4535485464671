import React from 'react';
import './PropertyDetailsExtended.css';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PropertyDetailsExtended: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation

  const projectsData = {
    labels: ['2020', '2021', '2022', '2023', '2024'],
    datasets: [
      {
        label: t('new_residential_projects'),
        data: [10, 15, 20, 25, 28],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.3,
      },
      {
        label: t('new_businesses'),
        data: [5, 8, 12, 18, 22],
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        tension: 0.3,
      },
    ],
  };

  const salesData = {
    labels: ['2020', '2021', '2022', '2023', '2024'],
    datasets: [
      {
        label: t('property_sales'),
        data: [50, 75, 100, 130, 160],
        backgroundColor: 'rgba(255, 159, 64, 0.8)',
        borderColor: 'rgba(255, 159, 64, 1)',
      },
    ],
  };

  return (
    <div className="extended-details-container">
      <h2>{t('extended_property_details')}</h2>
      <div className="stats-board">
        <div className="stats-item">
          <h3>{t('new_residential_projects')}</h3>
          <p>28 {t('projects')} (15% {t('increase_from_last_year')})</p>
        </div>
        <div className="stats-item">
          <h3>{t('new_businesses')}</h3>
          <p>22 {t('new_businesses')} (20% {t('increase_from_last_year')})</p>
        </div>
        <div className="stats-item">
          <h3>{t('new_startups')}</h3>
          <p>18 {t('new_startups')} (25% {t('increase_from_last_year')})</p>
        </div>
        <div className="stats-item">
          <h3>{t('property_sales')}</h3>
          <p>160 {t('properties_sold')} (23% {t('increase_from_last_year')})</p>
        </div>
      </div>

      <div className="extended-info-section">
        <h3>{t('market_offer_and_demand')}</h3>
        <p>{t('market_offer_and_demand_desc')}</p>
      </div>

      <div className="extended-info-section">
        <h3>{t('local_economic_situation')}</h3>
        <p>{t('local_economic_situation_desc')}</p>
      </div>

      <div className="extended-info-section">
        <h3>{t('other_insights')}</h3>
        <p>{t('other_insights_desc')}</p>
      </div>

      <div className="charts-section">
        <h3>{t('development_trends')}</h3>
        <Line data={projectsData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
      </div>

      <div className="charts-section">
        <h3>{t('property_sales_over_years')}</h3>
        <Bar data={salesData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
      </div>
    </div>
  );
};

export default PropertyDetailsExtended;
