import React from 'react';
import './SimilarPropertySales.css';

const SimilarPropertySales: React.FC = () => {
  return (
    <div className="similar-property-sales-container">
      <h2>Similar Property Sales in Griffintown</h2>
      <div className="sales-list">
        <div className="sale-item">
          <p>Address: 180 Rue Rioux, apt. 305, Montréal</p>
          <p>Sold Price: $365,000 CAD</p>
          <p>Beds: 1 | Baths: 1</p>
          <p>Sold Date: July 10, 2024</p>
        </div>
        <div className="sale-item">
          <p>Address: 175 Rue Peel, apt. 401, Montréal</p>
          <p>Sold Price: $380,000 CAD</p>
          <p>Beds: 2 | Baths: 1</p>
          <p>Sold Date: June 15, 2024</p>
        </div>
        <div className="sale-item">
          <p>Address: 200 Rue Young, apt. 102, Montréal</p>
          <p>Sold Price: $400,000 CAD</p>
          <p>Beds: 2 | Baths: 2</p>
          <p>Sold Date: May 20, 2024</p>
        </div>
      </div>
    </div>
  );
};

export default SimilarPropertySales;
