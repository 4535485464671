// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'; 

const firebaseConfig = {
    apiKey: "AIzaSyAk7KtUGCAwkf46fm3wTRsLD5E8goN7JjM",
    authDomain: "accurateator.firebaseapp.com",
    projectId: "accurateator",
    storageBucket: "accurateator.appspot.com",
    messagingSenderId: "443267511248",
    appId: "1:443267511248:web:8267a9ac279c842e1b3fd5"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
