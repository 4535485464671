import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore'; // Firestore functions
import { db } from '../firebaseConfig'; // Import the db from firebaseConfig
import './ContactUs.css'; // Import the new CSS for styling
import { useTranslation } from 'react-i18next'; // Import the translation hook
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState(''); // New state for comment section
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [isSaveSuccess, setIsSaveSuccess] = useState<boolean | null>(null); 
  const [captchaToken, setCaptchaToken] = useState<string | null>(null); // New state for reCAPTCHA token


  // Explicitly define the event type for form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Set loading to true while submitting
  
    if (!captchaToken) {  // Ensure reCAPTCHA is completed
      setMessage(t('please_complete_captcha')); // Translate CAPTCHA message
      setIsSaveSuccess(false);
      setLoading(false);
      return;
    }
  
    try {
      // Add email and comment to Firestore 'emailUpdates' collection
      await addDoc(collection(db, 'emailUpdates'), {
        email: email,
        comment: comment, // Save the comment in Firestore
        submittedAt: new Date(),
      });
  
      setMessage(t('message_submitted_successfully'));
      setIsSaveSuccess(true);
      setEmail('');
      setComment('');
    } catch (error) {
      setMessage(t('error_submitting_message') + ' ' + (error as Error).message);
      setIsSaveSuccess(false);
    } finally {
      setLoading(false); // Stop loading after submission
    }
  };  

  return (
    <div className="contact-us-container">
      <h2>{t('contact_us')}</h2> {/* Translate "Contact Us" */}
      <p>
        {t('reach_out_to_us')} <strong>team@rentfy.app</strong>
      </p>
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="email">{t('email')}:</label> 
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          aria-label={t('enter_your_email')}
          placeholder={t('enter_your_email')}
          className="email-input"
        />

        <label htmlFor="comment">{t('comment')}:</label> 
        <textarea
          id="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
          aria-label={t('write_your_message_here')}
          placeholder={t('write_your_message_here')}
          className="comment-input"
        />

        {/* Add reCAPTCHA component */}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LfVoVoqAAAAAKV9dvVOPy0-FZLSxDe-Co_d4MqF'}
          onChange={setCaptchaToken}
          className="mb-3"
        />

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? t('submitting') : t('submit')}
        </button>
      </form>
      {message && (
        <p className={isSaveSuccess ? 'success-message' : 'error-message'}>
          {message}
        </p>
      )}
    </div>
  );
};

export default ContactUs;
