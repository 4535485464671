import React, { useState } from 'react';
import axios from 'axios';
import './PropertyEvaluationPage.css'; // Make sure you create this CSS file for styling

const PropertyEvaluationPage: React.FC = () => {
  // State to hold form inputs
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [yearBuilt, setYearBuilt] = useState('');
  const [squareFootage, setSquareFootage] = useState('');
  const [ageOfProperty] = useState('');
  const [predictedPrice, setPredictedPrice] = useState<number | null>(null);
  const [confidenceScore, setConfidenceScore] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Function to handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    setErrorMessage(null);
    setPredictedPrice(null);
    setConfidenceScore(null);

    try {
      const response = await axios.post('http://localhost:5001/predict', {
        num_bedrooms: bedrooms,
        num_bathrooms: bathrooms,
        year_built: yearBuilt,
        square_footage: squareFootage,
        age_of_property: ageOfProperty,
      });

      const { prediction, confidenceScore } = response.data;
      setPredictedPrice(prediction);
      setConfidenceScore(confidenceScore);
    } catch (error) {
      console.error('Prediction error:', error);
      setErrorMessage('Failed to evaluate the property. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="evaluation-container">
      <h2>Evaluate Property Price in Griffintown, Montreal</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="bedrooms">Number of Bedrooms</label>
          <input
            type="number"
            id="bedrooms"
            value={bedrooms}
            onChange={(e) => setBedrooms(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="bathrooms">Number of Bathrooms</label>
          <input
            type="number"
            id="bathrooms"
            value={bathrooms}
            onChange={(e) => setBathrooms(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="yearBuilt">Year Built</label>
          <input
            type="number"
            id="yearBuilt"
            value={yearBuilt}
            onChange={(e) => setYearBuilt(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="squareFootage">Square Footage</label>
          <input
            type="number"
            id="squareFootage"
            value={squareFootage}
            onChange={(e) => setSquareFootage(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Evaluating...' : 'Evaluate'}
        </button>
      </form>

      {/* Display error message */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {/* Display prediction result */}
      {predictedPrice !== null && (
        <div className="result">
          <h3>Predicted Price: ${predictedPrice.toLocaleString()}K</h3>
          <p>Confidence Score: {confidenceScore}%</p>
        </div>
      )}
    </div>
  );
};

export default PropertyEvaluationPage;
