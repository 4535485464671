import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoogleMap, useJsApiLoader, OverlayView, InfoWindow } from '@react-google-maps/api';
import { FaInfoCircle, FaHeart } from 'react-icons/fa';
import { useAuth } from '../AuthContext';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next'; 
import styles from './Home.module.css';

interface Property {
  lat: number;
  lng: number;
  price: number;
  beds: number;
  baths: number;
  square_footage: number;
  address: string;
}

interface HomeProps {
  savedProperties: Property[];
  addPropertyToSaved: (property: Property) => void;
  removePropertyFromSaved: (property: Property) => void;
  toggleSidebar: () => void;  // New prop for toggling sidebar
  isSidebarOpen: boolean;     // New prop for sidebar state
}

const Home: React.FC<HomeProps> = ({
  addPropertyToSaved,
  removePropertyFromSaved,
  toggleSidebar,    
  isSidebarOpen     
}) => {
  const { t } = useTranslation(); // Initialize translation
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [properties, setProperties] = useState<Property[]>([]);
  const [filteredProperties, setFilteredProperties] = useState<Property[]>([]);
  const [averagePrice, setAveragePrice] = useState<number | null>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [mapPosition, setMapPosition] = useState<{ lat: number; lng: number }>({ lat: 45.4972, lng: -73.6104 });
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const [filters, setFilters] = useState({
    minPrice: 0,
    maxPrice: Infinity,
    minBeds: 0,
    maxBeds: Infinity,
    sortBy: 'price',
  });

  const containerStyle = {
  width: isSidebarOpen ? 'calc(100% - 250px)' : 'calc(100% - 60px)', // Adjust width based on sidebar
  height: '100vh', // Set to full height of the viewport
  transition: 'width 0.3s ease', // Smooth transition when toggling
};


  const [savedProperties, setSavedProperties] = useState<Property[]>([]);
  const [showFilters, setShowFilters] = useState(false);

  const { user } = useAuth();
  const firestore = getFirestore();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBVnzMCf44C2Gjuoo_4m02LjoTk_xm6aJ8',
    libraries: ['places'],
  });

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get('http://localhost:5001/getAllProperties');
        const propertiesData: Property[] = response.data.properties;
        setProperties(propertiesData);
        setFilteredProperties(propertiesData);

        const totalPrice = propertiesData.reduce((sum, property) => sum + property.price, 0);
        const avgPrice = totalPrice / propertiesData.length;
        setAveragePrice(avgPrice);

        if (propertiesData.length > 0) {
          setMapPosition({
            lat: propertiesData[0].lat,
            lng: propertiesData[0].lng,
          });
        }
      } catch (err) {
        console.error('Error fetching properties:', err);
        setError(t('error_loading_properties')); 
      }
    };

    fetchProperties();
  }, [t]);

  useEffect (() => {
    // Load Tawk.to script for chatbot integration
    const tawkScript = document.createElement('script');
    tawkScript.async = true;
    tawkScript.src = 'https://embed.tawk.to/66fc07104cbc4814f7e189b0/1i947n6lg';
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');
    document.body.appendChild(tawkScript);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(tawkScript);
    };
  })

  // Fetch saved properties from Firestore
  useEffect(() => {
    const fetchSavedProperties = async () => {
      if (user) {
        const userId = user.uid;
        try {
          const docRef = doc(firestore, 'users', userId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const savedPropertiesData = docSnap.data()?.savedProperties || [];
            setSavedProperties(savedPropertiesData);
          } else {
            console.log(t('no_saved_properties'));
          }
        } catch (error) {
          console.error(t('error_fetching_saved_properties'), error);
        }
      }
    };

    fetchSavedProperties();
  }, [user, firestore, t]);

  const handleSaveProperty = async (property: Property) => {
    if (user) {
      const userId = user.uid;
      try {
        const userDocRef = doc(firestore, 'users', userId);
        const isAlreadySaved = savedProperties.some(
          (savedProperty) => savedProperty.address === property.address
        );

        let updatedProperties;
        if (isAlreadySaved) {
          updatedProperties = savedProperties.filter(
            (savedProperty) => savedProperty.address !== property.address
          );
        } else {
          updatedProperties = [...savedProperties, property];
        }

        await setDoc(
          userDocRef,
          { savedProperties: updatedProperties },
          { merge: true }
        );

        setSavedProperties(updatedProperties);
      } catch (error) {
        console.error(t('error_saving_property'), error);
      }
    } else {
      console.error(t('user_not_logged_in'));
    }
  };

  useEffect(() => {
    if (inputValue.trim() !== '') {
      const filteredSuggestions = properties
        .filter((property) =>
          property.address.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((property) => property.address);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [inputValue, properties]);

  const handleSuggestionClick = (address: string) => {
    const selected = properties.find((property) => property.address === address);
    if (selected) {
      setMapPosition({ lat: selected.lat, lng: selected.lng });
      setSelectedProperty(selected);
      setSuggestions([]);
      setInputValue('');
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value === '' ? (e.target.name.includes('Price') ? Infinity : 0) : Number(e.target.value),
    });
  };

  useEffect(() => {
    const applyFilters = () => {
      const filtered = properties
        .filter((property) => property.price >= filters.minPrice && property.price <= filters.maxPrice)
        .filter((property) => property.beds >= filters.minBeds && property.beds <= filters.maxBeds)
        .sort((a, b) => (filters.sortBy === 'price' ? a.price - b.price : a.beds - b.beds));
      
      setFilteredProperties(filtered);
    };

    applyFilters();
  }, [filters, properties]);

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const formatPrice = (price: number) => {
    return price >= 1000000
      ? `$${(price / 1000000).toFixed(1)}m`
      : `$${(price / 1000).toFixed(0)}k`;
  };

  const getMarkerColor = (price: number): string => {
    if (!averagePrice) return '#FF5722';

    const lowerBound = averagePrice * 0.9;
    const upperBound = averagePrice * 1.1;

    if (price < lowerBound) return 'green';
    if (price >= lowerBound && price <= upperBound) return 'yellow';
    return 'orange';
  };

  const darkMapStyle = [
    {
      elementType: 'geometry',
      stylers: [{ color: '#212121' }],
    },
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#212121' }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#181818' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#1b1b1b' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [{ color: '#2c2c2c' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#8a8a8a' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#373737' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#3c3c3c' }],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry',
      stylers: [{ color: '#4e4e4e' }],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#000000' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#3d3d3d' }],
    },
  ];

  const theme = document.documentElement.getAttribute('data-theme');

  return (
    <div className={styles.mainContent}>
      <h1>{t('property_search_title')}</h1>
      <div className={styles.searchBarContainer}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={t('search_placeholder')}
          className={styles.inputBox}
        />
        {suggestions.length > 0 ? (
          <ul className={styles.suggestionsList}>
            {suggestions.map((suggestion, index) => (
              <li key={index} className={styles.suggestionItem} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        ) : (
          inputValue.trim() !== '' && <p className={styles.noSuggestionsMessage}>{t('no_suggestions')}</p>
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <button className={styles.filterButton} onClick={toggleFilters}>
          {showFilters ? t('hide_filters') : t('show_filters')}
        </button>
        <button className={styles.refreshButton} onClick={handleRefresh}>
          {t('refresh')}
        </button>
        <span className={styles.infoIcon}>
          <FaInfoCircle />
          <span className={styles.tooltipText}>
            {t('refresh_tooltip')}
          </span>
        </span>
      </div>
  
      {showFilters && (
        <div className={styles.filtersContainer}>
          <div>
            <label>{t('min_price')}</label>
            <input
              type="number"
              name="minPrice"
              value={filters.minPrice === Infinity ? '' : filters.minPrice}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <label>{t('max_price')}</label>
            <input
              type="number"
              name="maxPrice"
              value={filters.maxPrice === Infinity ? '' : filters.maxPrice}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <label>{t('min_beds')}</label>
            <input
              type="number"
              name="minBeds"
              value={filters.minBeds}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <label>{t('max_beds')}</label>
            <input
              type="number"
              name="maxBeds"
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <label>{t('sort_by')}</label>
            <select name="sortBy" value={filters.sortBy} onChange={handleFilterChange}>
              <option value="price">{t('price')}</option>
              <option value="beds">{t('num_beds')}</option>
            </select>
          </div>
        </div>
      )}
  
      {error && <p className={styles.errorMessage}>{error}</p>}
  
      <div className={styles.mapAndLegendContainer}></div>
  
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapPosition}
          zoom={15}
          options={{
            mapTypeId: 'roadmap',
            styles: theme === 'dark' ? darkMapStyle : [],
          }}
        >
          {filteredProperties.map((property, index) => (
            <OverlayView
              key={index}
              position={{ lat: property.lat, lng: property.lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => setSelectedProperty(property)}
              >
                <div
                  style={{
                    background: getMarkerColor(property.price),
                    color: 'black',
                    padding: '15px 10px',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    transform: 'translate(-50%, -100%)',
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                    width: '50px',
                    height: '10px',
                  }}
                >
                  {formatPrice(property.price)}
                </div>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    background: getMarkerColor(property.price),
                    transform: 'rotate(45deg)',
                    position: 'absolute',
                    left: '50%',
                    bottom: '-5px',
                    transformOrigin: 'center',
                    marginLeft: '-5px',
                  }}
                />
              </div>
            </OverlayView>
          ))}
  
          {selectedProperty && (
            <InfoWindow
              position={{ lat: selectedProperty.lat, lng: selectedProperty.lng }}
              onCloseClick={() => setSelectedProperty(null)}
            >
              <div
                style={{
                  maxWidth: '250px',
                  textAlign: 'left',
                  backgroundColor: theme === 'dark' ? '#2c2c2c' : 'white',
                  color: theme === 'dark' ? 'white' : 'black',
                  borderRadius: '8px',
                  padding: '10px',
                }}
              >
                <h3>{selectedProperty.address}</h3>
                <p>
                  {selectedProperty.beds} bds | {selectedProperty.baths} ba |{' '}
                  {selectedProperty.square_footage} sqft
                </p>
                <p>${selectedProperty.price}</p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FaHeart
                    style={{
                      cursor: 'pointer',
                      color: savedProperties.some(
                        (saved) => saved.address === selectedProperty?.address
                      )
                        ? 'red'
                        : 'gray',
                    }}
                    onClick={() => handleSaveProperty(selectedProperty)}
                  />
                  <button
                    style={{
                      backgroundColor: '#FF5722',
                      color: 'white',
                      border: 'none',
                      padding: '6px 12px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      (window.location.href = `/property/${selectedProperty.lat}/${selectedProperty.lng}`)
                    }
                  >
                    {t('see_more')}
                  </button>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );    
};

export default Home;
