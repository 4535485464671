import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import './Footer.css';

const Footer: React.FC = () => {
  const { t } = useTranslation(); // Hook for translations
  const [isVisible, setIsVisible] = useState(false); // Track the visibility of the footer

  // Function to check if the user has scrolled to the bottom
  const controlFooter = useCallback(() => {
    const scrolledToBottom =
      window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;

    if (scrolledToBottom) {
      setIsVisible(true); // Show the footer when the user has scrolled to the bottom
    } else {
      setIsVisible(false); // Hide the footer when not at the bottom
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', controlFooter); // Add event listener for scrolling

    return () => {
      window.removeEventListener('scroll', controlFooter); // Clean up the event listener
    };
  }, [controlFooter]); // Only include controlFooter in the dependency array

  return (
    <footer className={`footer-container ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} {t('real_estate_app')}. {t('all_rights_reserved')}.</p>
        <div className="footer-links">
          <a href="/contact-us">{t('contact')}</a>
          <a href="/privacy">{t('privacy_policy')}</a>
          <a href="/faq">{t('faq')}</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
