import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'; 
import { useParams, useNavigate } from 'react-router-dom'; 
import { FaInfoCircle, FaArrowLeft } from 'react-icons/fa'; 
import './PropertyDetails.css';
import { getAuth } from "firebase/auth";
import { useTranslation } from 'react-i18next';  // Add translation hook

interface Landmark {
    name: string;
    distance: string | number;
}  

interface PropertyDetailsData {
  lat: number;
  lng: number;
  price: number;
  beds: number;
  baths: number;
  square_footage: number;
  address: string;
  year_built: number;
  condition?: number;  
  property_id?: string;
}

const PropertyDetailsComponent: React.FC = () => {  
  const { t } = useTranslation();  // Initialize translation
  const { lat, lng } = useParams<{ lat: string, lng: string }>(); 
  const [property, setProperty] = useState<PropertyDetailsData | null>(null);
  // const [updatedProperty, setUpdatedProperty] = useState<PropertyDetailsData | null>(null);  
  const [predictedPrice, setPredictedPrice] = useState<number | null>(null);
  const [confidenceScore, setConfidenceScore] = useState<number | null>(null);
  const [marketCondition, setMarketCondition] = useState<string | null>(null);
  const [priceChange, setPriceChange] = useState<number | null>(null);
  const [marketChangePercentage, setMarketChangePercentage] = useState<number | null>(null);
  const [loadingPrediction, setLoadingPrediction] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [propertyId, setPropertyId] = useState<string | null>(null); 
  const [landmarks, setLandmarks] = useState<Landmark[]>([]); 
  const [propertyCondition, setPropertyCondition] = useState<number>(3); 
  const [evaluationCount, setEvaluationCount] = useState<number>(0); // To track evaluations
  const [userPlan, setUserPlan] = useState<string>(''); // To store the user's plan
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBVnzMCf44C2Gjuoo_4m02LjoTk_xm6aJ8',
  });

  const handleViewMoreClick = () => {
    navigate(`/property-details-extended`);
  };

  const navigate = useNavigate();

  const calculatePropertyCondition = (yearBuilt: number): number => {
    const currentYear = new Date().getFullYear();
    const age = currentYear - yearBuilt;
    let condition = 5 - Math.floor(age / 2);
    return Math.max(condition, 2);
  };
  
  const fetchNearbyLandmarks = useCallback(async (lat: number, lng: number) => {
    try {
      const response = await axios.post('http://localhost:5001/getNearbyLandmarks', { lat, lng });
      const { landmarks } = response.data;
      setLandmarks(landmarks);
    } catch (error) {
      console.error(t('error_fetching_landmarks'), error);  // Translated error
    }
  }, [t]);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        const parsedLat = lat ? parseFloat(lat) : 0;
        const parsedLng = lng ? parseFloat(lng) : 0;

        const response = await axios.post('http://localhost:5001/getPropertyData', {
          lat: parsedLat,
          lng: parsedLng,
        });

        const data = response.data;
        setProperty(data);
        // setUpdatedProperty(data); 

        if (data.year_built) {
          const condition = data.condition || calculatePropertyCondition(data.year_built);
          setPropertyCondition(condition);
        }

        fetchNearbyLandmarks(parsedLat, parsedLng);  // Call the memoized function

        if (data.property_id) {
          setPropertyId(data.property_id); 
        } else {
          console.error(t('property_id_not_found'));
        }        
      } catch (err) {
        console.error(t('error_fetching_property_details'), err); // Translated error
      }
    };

    fetchPropertyDetails();
  }, [lat, lng, t, fetchNearbyLandmarks]);

  useEffect(() => {
    const tawkScript = document.createElement('script');
    tawkScript.async = true;
    tawkScript.src = 'https://embed.tawk.to/66fc07104cbc4814f7e189b0/1i947n6lg';
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');
    document.body.appendChild(tawkScript);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(tawkScript);
    };
  });

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
  
        if (user) {
          const email = user.email;
          const response = await axios.get(`http://localhost:5001/getUserPlan`, {
            params: { email }
          });
  
          const plan = response.data.plan || '';
          setUserPlan(plan); // Set the user plan state
          console.log("User Plan:", plan);
  
        } else {
          console.error(t('no_user_logged_in'));
        }
      } catch (error) {
        console.error(t('error_fetching_user_plan'), error);
      }
    };
    fetchUserPlan();
  }, [t]);  
  
  // Commenting out unused `handleInputChange` function
  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof PropertyDetailsData) => {
  //   if (updatedProperty) {
  //     setUpdatedProperty({ ...updatedProperty, [field]: e.target.value });
  //   }
  // };

  const handlePredictPrice = async () => {
    if (!property) return;
  
    if (userPlan === 'Free Plan' && evaluationCount >= 3) {  
      setErrorMessage(t('evaluation_limit_reached')); 
      return;  
    }
  
    setLoadingPrediction(true);
    setErrorMessage(null);
    setPredictedPrice(null);
  
    try {
      const response = await axios.post('http://localhost:5001/predict', {
        num_bedrooms: property.beds,
        num_bathrooms: property.baths,
        square_footage: property.square_footage,
        condition: propertyCondition,
        price: property.price,
        latitude: property.lat,
        longitude: property.lng,
        property_id: propertyId,
      });
  
      const { prediction, confidenceScore, marketCondition, priceChange, marketChangePercentage } = response.data;
  
      setPredictedPrice(prediction);
      setConfidenceScore(confidenceScore);
      setMarketCondition(marketCondition);
      setPriceChange(priceChange);
      setMarketChangePercentage(marketChangePercentage);
  
      if (userPlan === 'Free Plan') {
        setEvaluationCount(prevCount => prevCount + 1);  // Increment the evaluation count for Free Plan users
      }
  
    } catch (err) {
      setErrorMessage(t('error_price_prediction'));  // Translated error message
    } finally {
      setLoadingPrediction(false);
    }
  };  

  const handleViewSimilarSalesClick = () => {
    navigate(`/similar-property-sales`);
  };
  
  if (!property || !isLoaded) return <p>{t('loading')}</p>;

  return (
    <div className="property-details-container">
      <div className="back-button-container" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <FaArrowLeft className="back-button" onClick={() => navigate(-1)} style={{ cursor: 'pointer', fontSize: '20px', marginRight: '10px' }} />
        <h2>{t('property_details')}</h2>
      </div>

      <div className="property-details">
        <p>{t('address')}: {property.address}</p>
        <p>{t('selling_price')}: ${property.price} CAD</p>
        <p>{t('beds')}: {property.beds}</p>
        <p>{t('baths')}: {property.baths}</p>
        <p>{t('square_footage')}: {property.square_footage}</p>
        <p>{t('year_built')}: {property.year_built ? property.year_built : t('not_available')}</p>
        <p>
          {t('property_age')}: 
          {property.year_built 
            ? `${new Date().getFullYear() - property.year_built} ${t('years')}`
            : t('not_available')}
        </p>
        <button className="view-more-button" onClick={handleViewSimilarSalesClick}>
          {t('view_similar_property_sales')}
        </button>
      </div>

      <div className="landmark-features">
        <h3>{t('nearby_landmarks')}</h3>
        {landmarks.length > 0 ? (
          <ul>
            {landmarks.map((landmark, index) => (
              <li key={index}>
                {landmark.name}: {typeof landmark.distance === 'string' ? parseFloat(landmark.distance).toFixed(2) : landmark.distance} KM
              </li>
            ))}
          </ul>
        ) : (
          <p>{t('no_landmarks_found')}</p>
        )}
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <button className="predict-price-button" onClick={handlePredictPrice} disabled={loadingPrediction}>
          {loadingPrediction ? t('evaluating') : t('automatic_valuation')}
        </button>
        <div className="info-icon-container" style={{ position: 'relative', marginLeft: '10px' }}>
          <FaInfoCircle className="info-icon" />
          <div className="tooltip">
            {t('valuation_tooltip')}
          </div>
        </div>
      </div>

      {loadingPrediction && <p>{t('loading_prediction')}</p>}

      {predictedPrice && (
      <div className="prediction-result">
        <h3>{t('automatic_valuated_price')}: ${predictedPrice}K</h3>
        <p>{t('confidence_score')}: {confidenceScore}%</p>
        {marketCondition && <p>{t('market_condition')}: {marketCondition}</p>}
        {priceChange !== null && (
          <p>
            {priceChange > 0
              ? `${t('price_increased')} ${priceChange}% ${t('due_to_seller_market')}`
              : priceChange < 0
                ? `${t('price_decreased')} ${Math.abs(priceChange)}% ${t('due_to_buyer_market')}`
                : t('neutral_market')}
          </p>
        )}
        {marketChangePercentage !== null && (
          <p>
            {marketChangePercentage > 0 ? t('property_performance_increased') : marketChangePercentage < 0 ? t('property_performance_decreased') : t('property_performance_neutral')} {marketChangePercentage}%.
          </p>
        )}
        <button className="view-more-button" onClick={handleViewMoreClick}>
          {t('view_more')}
        </button>
      </div>
    )}

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <div className="map-container">
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={{ lat: property.lat, lng: property.lng }}
          zoom={20}
          options={{ mapTypeId: 'satellite' }}
        >
          <Marker position={{ lat: property.lat, lng: property.lng }} />
        </GoogleMap>
      </div>
    </div>
  );
};

export default PropertyDetailsComponent;
