import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import { useTranslation } from 'react-i18next';
// import { loadStripe } from '@stripe/stripe-js';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import ReCAPTCHA from 'react-google-recaptcha';

const LandingPage: React.FC = () => {
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'light');
  // const stripePromise = loadStripe('pk_live_51PT2l3Gh2nZYLcwXOoaamPlfu3NrE4bc8etYdKJqlUkj8eeFZznvrsFYlRsMtQCZcd5onBYgsT7N9Drj7eqaT9jv007qL9uJTd'); 
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState(''); // New state for comment section
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [isSaveSuccess, setIsSaveSuccess] = useState<boolean | null>(null); 
  const [captchaToken, setCaptchaToken] = useState<string | null>(null); // New state for reCAPTCHA token

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const { i18n, t } = useTranslation();  // Use 't' for translation keys

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Load the Stripe Pricing Table when the component mounts
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Load Tawk.to script for chatbot integration
    const tawkScript = document.createElement('script');
    tawkScript.async = true;
    tawkScript.src = 'https://embed.tawk.to/66fc07104cbc4814f7e189b0/1i947n6lg';
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');
    document.body.appendChild(tawkScript);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(tawkScript);
    };
  }, []);

  // Explicitly define the event type for form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Set loading to true while submitting
  
    if (!captchaToken) {  // Ensure CAPTCHA is completed
      setMessage(t('please_complete_captcha')); // Translate CAPTCHA message
      setIsSaveSuccess(false);
      setLoading(false);
      return;
    }
  
    try {
      // Add email and comment to Firestore 'emailUpdates' collection
      await addDoc(collection(db, 'emailUpdates'), {
        email: email,
        comment: comment,
        submittedAt: new Date(),
      });
  
      setMessage(t('message_submitted_successfully'));
      setIsSaveSuccess(true);
      setEmail('');
      setComment('');
    } catch (error) {
      setMessage(t('error_submitting_message') + ' ' + (error as Error).message);
      setIsSaveSuccess(false);
    } finally {
      setLoading(false); // Stop loading after submission
    }
  };
  
  return (
    <div className="landing-container">
      <header className="header">
        <div className="logo">
          <Link to="/">{t('Accurateator')}</Link>  {/* Use translation key for brand name */}
        </div>
        <nav className="navbar">
          <ul className="nav-links">
            <li><Link to="/">{t('home')}</Link></li>
            <li><Link to="/pricing-plan">{t('pricing')}</Link></li>
            <li><Link to="/login">{t('login')}</Link></li>
            <li><Link to="/signup">{t('signUp')}</Link></li>
          </ul>
          <div className="theme-toggle">
            <label className="switch">
              <input type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="language-switch">
            <button className="dropdown-btn">
              {i18n.language === 'fr' ? 'Français' : 'English'}
            </button>
            <div className="dropdown-content">
              <button onClick={() => changeLanguage('fr')}>Français</button>
              <button onClick={() => changeLanguage('en')}>English</button>
            </div>
          </div>
        </nav>
      </header>
      
      <main className="main-content">
        <section
          className="hero-section"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/accurateator_image.svg'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '500px',
          }}
        >
          <h1>{t('welcome')}</h1>  {/* Translation key for Welcome */}
          <p>{t('welcomeMessage')}</p>  {/* Translation key for welcome message */}
          <div className="cta-buttons">
            <Link to="/signup" className="primary-btn">{t('getStarted')}</Link>
            <Link to="/evaluate-property" className="secondary-btn">{t('evaluateProperty')}</Link>
          </div>
        </section>

        {/* Embed YouTube video below the hero section */}
        <section className="youtube-video-section">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/tpHGHJjSJjA?si=9V2CBazn_8D3HnOT"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </section>

        <section className="features-section">
          <h2>{t('whyChoose')}</h2>  {/* Translation key for Why Choose */}
          <div className="features">
            <div className="feature">
              <h3>{t('instantValuation')}</h3>
              <p>{t('instantDescription')}</p>
            </div>
            <div className="feature">
              <h3>{t('dataDrivenInsights')}</h3>
              <p>{t('dataDescription')}</p>
            </div>
            <div className="feature">
              <h3>{t('simpleInterface')}</h3>
              <p>{t('simpleDescription')}</p>
            </div>
          </div>
        </section>

        <section className="features-section">
          <h2>{t('whatWeOffer')}</h2>
          <div className="features">
            <div className="feature">
              <p>{t('offer1')}</p>
            </div>
            <div className="feature">
              <p>{t('offer3')}</p>
            </div>
            <div className="feature">
              <p>{t('offer4')}</p>
            </div>
            <div className="feature">
              <p>{t('offer6')}</p>
            </div>
            <div className="feature">
              <p>{t('offer7')}</p>
            </div>
          </div>
          <div className="cta-buttons">
            <Link to="/signup" className="secondary-btn">{t('evaluateProperty')}</Link>
          </div>
        </section>

        <section className='pricing'>
          {/* Stripe Pricing Table */}
          <div className="stripe-pricing-table-container">
            <h1>Accurateator Plans</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: `
                  <stripe-pricing-table
                    pricing-table-id="prctbl_1Q2iYVGh2nZYLcwXL4IodjXO"
                    publishable-key="pk_live_51PT2l3Gh2nZYLcwXOoaamPlfu3NrE4bc8etYdKJqlUkj8eeFZznvrsFYlRsMtQCZcd5onBYgsT7N9Drj7eqaT9jv007qL9uJTd">
                  </stripe-pricing-table>`,
              }}
            />
          </div>
        </section>
      </main>

      <div className="contact-us-container">
        <h2>{t('contact_us')}</h2> {/* Translate "Contact Us" */}
        <p>
          {t('reach_out_to_us')} <strong>team@rentfy.app</strong> {t('or_use_the_form')}
        </p>
        <form onSubmit={handleSubmit} className="contact-form">
          <label htmlFor="email">{t('email')}:</label> 
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            aria-label={t('enter_your_email')}
            placeholder={t('enter_your_email')}
            className="email-input"
          />

          <label htmlFor="comment">{t('comment')}:</label> 
          <textarea
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
            aria-label={t('write_your_message_here')}
            placeholder={t('write_your_message_here')}
            className="comment-input"
          />

          {/* Add reCAPTCHA component */}
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LfVoVoqAAAAAKV9dvVOPy0-FZLSxDe-Co_d4MqF'}
            onChange={setCaptchaToken}
            className="mb-3"
          />

          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? t('submitting') : t('submit')}
          </button>
        </form>

        {message && (
          <p className={isSaveSuccess ? 'success-message' : 'error-message'}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default LandingPage;