import React, { useEffect } from 'react';
import './PricingPlan.css';
// import { loadStripe } from '@stripe/stripe-js';

// Replace with your correct publishable key from the Stripe Dashboard
// const stripePromise = loadStripe('pk_live_51PT2l3Gh2nZYLcwXOoaamPlfu3NrE4bc8etYdKJqlUkj8eeFZznvrsFYlRsMtQCZcd5onBYgsT7N9Drj7eqaT9jv007qL9uJTd');  // OR 'pk_test_...' for test mode

const PricingPlan: React.FC = () => {

  useEffect(() => {
    // Load the Stripe Pricing Table when the component mounts
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="pricing-container">
      <h1>Accurateator Subscription Plans</h1>
      <p>Choose a plan that fits your needs and access advanced features</p>

      {/* Stripe Pricing Table */}
      <div className="stripe-pricing-table-container">
        <div
          dangerouslySetInnerHTML={{
            __html: `
              <stripe-pricing-table
                pricing-table-id="prctbl_1Q2iYVGh2nZYLcwXL4IodjXO"
                publishable-key="pk_live_51PT2l3Gh2nZYLcwXOoaamPlfu3NrE4bc8etYdKJqlUkj8eeFZznvrsFYlRsMtQCZcd5onBYgsT7N9Drj7eqaT9jv007qL9uJTd">
              </stripe-pricing-table>`,
          }}
        />
      </div>
    </div>
  );
};

export default PricingPlan;
