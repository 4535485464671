import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './SavedProperties.css'

// Define Property Type
interface Property {
  lat: number;
  lng: number;
  price: number;
  beds: number;
  baths: number;
  square_footage: number;
  address: string;
}

// Define the props for SavedProperties
interface SavedPropertiesProps {
  savedProperties: Property[];
  removeProperty: (property: Property) => void;
  toggleSidebar: () => void;
  isSidebarOpen: boolean;
}

const SavedProperties: React.FC<SavedPropertiesProps> = ({
  savedProperties: initialSavedProperties,
  removeProperty,
  toggleSidebar,
  isSidebarOpen
}) => {
  const { t } = useTranslation();
  const [savedProperties, setSavedProperties] = useState<Property[]>(initialSavedProperties);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSavedProperties = async () => {
      if (user) {
        try {
          const userId = user.uid;
          const firestore = getFirestore();
          const docRef = doc(firestore, 'users', userId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const savedPropertiesData = docSnap.data().savedProperties || [];
            setSavedProperties(savedPropertiesData);
          } else {
            console.log('No saved properties found for this user.');
          }
        } catch (error) {
          console.error('Error fetching saved properties:', error);
        }
      }
    };

    fetchSavedProperties();
  }, [user]);

  useEffect(() => {
    const tawkScript = document.createElement('script');
    tawkScript.async = true;
    tawkScript.src = 'https://embed.tawk.to/66fc07104cbc4814f7e189b0/1i947n6lg';
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');
    document.body.appendChild(tawkScript);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(tawkScript);
    };
  });

  const handleRemoveProperty = async (property: Property) => {
    if (user) {
      try {
        const userId = user.uid;
        const firestore = getFirestore();
        const docRef = doc(firestore, 'users', userId);

        const updatedProperties = savedProperties.filter((p) => p.address !== property.address);
        await updateDoc(docRef, { savedProperties: updatedProperties });

        setSavedProperties(updatedProperties);
        removeProperty(property);
      } catch (error) {
        console.error('Error removing property:', error);
      }
    }
  };

  const handleSeeMore = (property: Property) => {
    navigate(`/property/${property.lat}/${property.lng}`);
  };

  return (
    <div className={`property-list-container ${isSidebarOpen ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
      <h1>{t('saved_properties')}</h1>
      {savedProperties.length === 0 ? (
        <p>{t('no_properties_saved')}</p>
      ) : (
        <div className="property-cards">
          {savedProperties.map((property, index) => (
            <div key={index} className="property-card">
              <div className="property-card-details">
                <p><strong>{t('address')}:</strong> {property.address}</p>
                <p><strong>{t('beds')}:</strong> {property.beds}</p>
                <p><strong>{t('baths')}:</strong> {property.baths}</p>
                <p><strong>{t('square_footage')}:</strong> {property.square_footage} sqft</p>
                <p className="property-price-tag">${property.price}</p>
              </div>
              <div className="property-card-actions">
                <button className="btn-remove" onClick={() => handleRemoveProperty(property)}>
                  {t('remove')}
                </button>
                <button className="btn-see-more" onClick={() => handleSeeMore(property)}>
                  {t('see_more')}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SavedProperties;
