import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // For navigation
import { useAuth } from '../AuthContext'; // Import the useAuth hook for Firebase Auth context
import { useTranslation } from 'react-i18next'; // For translations
import './Settings.css'

const Settings: React.FC = () => {
  const { t } = useTranslation(); // Hook for translations
  const { user } = useAuth(); // Access the authenticated user
  const firestore = getFirestore();
  const navigate = useNavigate(); // Hook to handle navigation

  // State to manage preferences and profile information
  const [preferences, setPreferences] = useState({
    notificationsEnabled: false,
    emailSubscription: false,
  });
  const [profile, setProfile] = useState({
    name: '',
    email: '',
  });
  const [savedSearches, setSavedSearches] = useState<string[]>([]); // Array for saved searches/alerts

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Fetch preferences and profile from Firestore
  useEffect(() => {
    const fetchPreferencesAndProfile = async () => {
      if (user) {
        try {
          const docRef = doc(firestore, 'users', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            const userPreferences = userData?.preferences || {};
            const userProfile = {
              name: userData?.name || '',
              email: userData?.email || user.email,
            };
            const userSavedSearches = userData?.savedSearches || [];

            setPreferences(userPreferences);
            setProfile(userProfile);
            setSavedSearches(userSavedSearches);
          } else {
            console.log(t('no_preferences_found')); // Log if no preferences are found
          }
        } catch (error) {
          setError(t('failed_to_fetch_preferences'));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPreferencesAndProfile();
  }, [user, firestore, t]);

  // Handle preference changes
  const handlePreferenceChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      [name]: checked,
    }));

    if (name === 'emailSubscription' && checked) {
      // Add user email to emailUpdates collection when subscribed
      try {
        const emailUpdateDocRef = doc(firestore, 'emailUpdates', user?.uid || '');
        await setDoc(emailUpdateDocRef, { email: user?.email });
        setSuccessMessage(t('email_subscription_updated'));
      } catch (error) {
        console.error(t('error_subscribing_email_updates'), error);
        setError(t('failed_to_subscribe_email_updates'));
      }
    } else if (name === 'emailSubscription' && !checked) {
      // Remove user email from emailUpdates collection when unsubscribed
      try {
        const emailUpdateDocRef = doc(firestore, 'emailUpdates', user?.uid || '');
        await setDoc(emailUpdateDocRef, { email: '' }); // Clear email
        setSuccessMessage(t('email_subscription_removed'));
      } catch (error) {
        console.error(t('error_unsubscribing_email_updates'), error);
        setError(t('failed_to_unsubscribe_email_updates'));
      }
    }
  };

  // Handle profile changes
  const handleProfileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  // Save preferences and profile to Firestore
  const savePreferences = async () => {
    if (user) {
      try {
        const docRef = doc(firestore, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // If document exists, update it
          await updateDoc(docRef, { preferences: preferences, profile: profile });
          setSuccessMessage(t('preferences_updated'));
        } else {
          // If document does not exist, create it
          await setDoc(docRef, { preferences: preferences, profile: profile });
          setSuccessMessage(t('preferences_created'));
        }
      } catch (error) {
        setError(t('failed_to_save_preferences'));
        console.error(t('error_saving_preferences'), error);
      }
    } else {
      setError(t('user_not_logged_in'));
    }
  };

  // Handle saved search deletion
  const deleteSavedSearch = async (search: string) => {
    if (user) {
      try {
        const updatedSearches = savedSearches.filter((s) => s !== search);
        const docRef = doc(firestore, 'users', user.uid);
        await updateDoc(docRef, { savedSearches: updatedSearches });
        setSavedSearches(updatedSearches);
      } catch (error) {
        console.error(t('error_deleting_saved_search'), error);
      }
    }
  };

  // Navigate to Saved Properties
  const handleViewSavedSearches = () => {
    navigate('/saved-properties');
  };

  return (
    <div className="settings-container">
      <h1>{t('user_settings')}</h1>

      {loading ? (
        <p>{t('loading_preferences')}</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div className="settings-form">
          {/* Profile Information */}
          <div className="profile-section">
            <h2>{t('profile_information')}</h2>
            <div className="setting-item">
              <label>
                {t('name')}:
                <input
                  type="text"
                  name="name"
                  value={profile.name}
                  onChange={handleProfileChange}
                />
              </label>
            </div>

            <div className="setting-item">
              <label>
                {t('email')}:
                <input
                  type="email"
                  name="email"
                  value={profile.email}
                  onChange={handleProfileChange}
                />
              </label>
            </div>
          </div>

          {/* Notifications Toggle */}
          <div className="setting-item">
            <label>
              <input
                type="checkbox"
                name="notificationsEnabled"
                checked={preferences.notificationsEnabled}
                onChange={handlePreferenceChange}
              />
              {t('enable_notifications')}
            </label>
          </div>

          {/* Email Subscription Toggle */}
          <div className="setting-item">
            <label>
              <input
                type="checkbox"
                name="emailSubscription"
                checked={preferences.emailSubscription}
                onChange={handlePreferenceChange}
              />
              {t('subscribe_to_email_updates')}
            </label>
          </div>

          <h3>{t('change_plan')}</h3>
          <button className="upgrade-button" onClick={() => navigate('/pricing-plan')}>
            {t('upgrade')}
          </button>

          {/* Saved Searches Management */}
          <div className="saved-searches-section">
            <h2>{t('saved_searches')}</h2>
            {savedSearches.length === 0 ? (
              <p>{t('no_saved_searches')}</p>
            ) : (
              <ul>
                {savedSearches.map((search, index) => (
                  <li key={index}>
                    {search}
                    <button onClick={() => deleteSavedSearch(search)} className="delete-button">
                      {t('delete')}
                    </button>
                  </li>
                ))}
              </ul>
            )}

            <button onClick={handleViewSavedSearches} className="view-saved-searches-button">
              {t('view_saved_searches')}
            </button>
          </div>

          <button onClick={savePreferences} className="save-button">
            {t('save_preferences')}
          </button>

          {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
      )}
    </div>
  );
};

export default Settings;
