import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import './MarketInsight.css'; // Ensure you have this CSS file for styling
import { useTranslation } from 'react-i18next'; // Import the translation hook

const MarketInsight: React.FC = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [location, setLocation] = useState('Montréal'); // Default location set to Montréal
  const [, setSuggestions] = useState<any[]>([]); // Adjust type to handle complex suggestion objects
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch location suggestions
  const fetchLocationSuggestions = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('http://localhost:5001/getLocationSuggestions', {
        params: { location },
      });

      // Log the data for debugging (optional)
      console.log('Location Suggestions:', response.data.suggestions);

      setSuggestions(response.data.suggestions); 
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
      setError(t('error_fetching_location_suggestions'));
    } finally {
      setLoading(false);
    }
  }, [t, location]);

  useEffect(() => {
    fetchLocationSuggestions();
    // Load Tawk.to script for chatbot integration
    const tawkScript = document.createElement('script');
    tawkScript.async = true;
    tawkScript.src = 'https://embed.tawk.to/66fc07104cbc4814f7e189b0/1i947n6lg';
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');
    document.body.appendChild(tawkScript);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(tawkScript);
    };
  }, [fetchLocationSuggestions, location]);

  return (
    <div className="market-insight-container">
      <h1>{t('market_insights_for')} {location}</h1>
      
      {/* Input to change location */}
      <div className="location-input">
        <label htmlFor="location">{t('enter_location')}</label>
        <input
          type="text"
          id="location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder={t('enter_city_or_location')}
        />
        <button onClick={fetchLocationSuggestions} disabled={loading}>
          {loading ? t('loading') : t('get_insights')} {/* Translate button text */}
        </button>
      </div>

      {/* Error message displayed at the bottom */}
      <div className="bottom-error-message">
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default MarketInsight;
